@charset "UTF-8";
.badge {
  font-size: 12px;
  font-weight: normal;
  color: #FFFFFF; }
  .badge.badge-primary {
    background-color: #0097A7; }
  .badge.badge-secondary {
    background-color: #8A8A8E; }
  .badge.badge-accent {
    background-color: #FF9800; }
  .badge.badge-danger {
    background-color: #EF5350; }
  .badge.badge-outline-primary {
    border: 1px solid #0097A7;
    color: #0097A7;
    background: transparent; }
  .badge.badge-outline-secondary {
    border: 1px solid #8A8A8E;
    color: #8A8A8E;
    background: transparent; }
  .badge.badge-outline-accent {
    border: 1px solid #FF9800;
    color: #FF9800;
    background: transparent; }
  .badge.badge-outline-danger {
    border: 1px solid #EF5350;
    color: #EF5350;
    background: transparent; }

.banner {
  position: relative;
  height: 40vh;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center; }
  .banner::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.5); }
  .banner .banner-image {
    position: absolute;
    background-size: cover;
    background-position: center top;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0; }
  .banner .banner-content {
    z-index: 1; }

.btn {
  padding-left: 2rem;
  padding-right: 2rem;
  color: #0097A7;
  border: 2px solid #0097A7;
  border-radius: 0;
  transition: 0.5s;
  font-weight: bold; }
  .btn:hover {
    opacity: 0.9;
    color: #FFFFFF;
    background-color: #0097A7; }
  .btn.badge-pill {
    border-radius: 10rem; }
  .btn.btn-lg {
    padding: 0.75rem 4rem;
    font-size: 18px; }
  .btn.btn-primary {
    color: #FFFFFF;
    background-color: #0097A7 !important;
    border: none !important; }
  .btn.btn-accent {
    color: #FFFFFF;
    background-color: #FF9800;
    border: none !important; }
  .btn.btn-secondary {
    color: #FFFFFF;
    background-color: #8A8A8E !important;
    border: none !important; }
  .btn.btn-twitter {
    color: #FFFFFF;
    background-color: #1D9BF0 !important;
    border: none !important; }
  .btn.btn-youtube {
    color: #FFFFFF;
    background-color: #FF0000 !important;
    border: none !important; }

.card {
  border: none;
  background-color: transparent;
  margin-bottom: 2rem; }
  .card img {
    margin-bottom: 0.75rem; }
  .card .card-header {
    padding: 0 0 0.75rem 0;
    background-color: transparent;
    border-bottom: none; }
    .card .card-header .card-heading {
      margin-bottom: 0.25rem;
      font-size: 14px;
      color: rgba(60, 60, 67, 0.6);
      line-height: 1.2; }
    .card .card-header .card-title {
      display: inline-block;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 16px;
      color: #000000;
      line-height: 1.2; }
  .card .card-body {
    padding: 0 0 1.25rem 0; }
    .card .card-body .card-text {
      color: rgba(60, 60, 67, 0.6);
      line-height: 1.5; }
  .card a .card-img-hover-mask {
    position: relative; }
    .card a .card-img-hover-mask .card-img-hover-mask-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 0.75rem);
      background-color: rgba(0, 0, 0, 0.5);
      color: #FFFFFF;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0; }
  .card a:hover {
    text-decoration: none; }
    .card a:hover .card-img-hover-mask .card-img-hover-mask-content {
      opacity: 1; }

.close {
  color: rgba(60, 60, 67, 0.6);
  opacity: 1;
  text-shadow: none;
  width: 30px;
  height: 30px;
  border-radius: 10rem; }
  .close:hover {
    color: #000000;
    opacity: 1 !important; }

.footer-links .footer-link {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 0.25rem;
  text-align: center; }
  .footer-links .footer-link .icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .footer-links .footer-link .icon-container::before {
      width: 80px;
      padding-top: 80px;
      content: "";
      background-color: #FAFAFA;
      border-radius: 50%; }
    .footer-links .footer-link .icon-container .icon {
      position: absolute;
      font-size: 40px;
      color: #0097A7; }

#footer {
  padding-top: 100px;
  padding-bottom: 2rem;
  color: rgba(60, 60, 67, 0.6);
  font-size: 14px; }
  #footer a {
    color: rgba(60, 60, 67, 0.6); }
  #footer ul {
    padding-left: 0;
    margin-bottom: 0; }
    #footer ul li {
      display: inline-block; }
      #footer ul li:not(:last-child) {
        padding-right: 1rem; }
  #footer .footer-brand {
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%; }
    #footer .footer-brand .logo img {
      height: 40px; }
    #footer .footer-brand ul.social-icons {
      display: flex; }
      #footer .footer-brand ul.social-icons li {
        font-size: 28px;
        line-height: 1; }
        #footer .footer-brand ul.social-icons li a.twitter {
          color: #1D9BF0; }
        #footer .footer-brand ul.social-icons li a.facebook {
          color: #1877F2; }
  #footer .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    line-height: 2; }
    #footer .footer-bottom .isms {
      text-align: center;
      margin-bottom: 1rem; }
      #footer .footer-bottom .isms img {
        height: 80px; }
  @media (min-width: 768px) {
    #footer ul li {
      display: block; }
      #footer ul li:not(:last-child) {
        padding-right: 0; }
      #footer ul li.mt {
        margin-top: 1rem; }
    #footer .footer-brand {
      padding-bottom: 0;
      flex-direction: column;
      align-items: flex-start; }
      #footer .footer-brand .isms {
        margin-bottom: 1rem; }
      #footer .footer-brand ul.social-icons li:not(:last-child) {
        padding-right: 1rem; }
    #footer .footer-bottom {
      flex-direction: row;
      justify-content: space-between; } }

form .form-control {
  border: 1px solid #C6C6C8; }
  form .form-control:invalid {
    background-color: #FFF4E5; }

form .form-group .form-check {
  position: relative;
  margin-bottom: 0.5rem; }
  form .form-group .form-check input[type=checkbox] {
    opacity: 0; }
    form .form-group .form-check input[type=checkbox] + label {
      cursor: pointer; }
      form .form-group .form-check input[type=checkbox] + label::before {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 5px;
        left: 0;
        content: "";
        border: 1px solid #C6C6C8;
        border-radius: 3px;
        background-color: #FFFFFF; }
      form .form-group .form-check input[type=checkbox] + label::after {
        position: absolute;
        width: 14px;
        height: 14px;
        top: 8px;
        left: 3px;
        content: "";
        background-image: url("/assets/img/common/check.svg?t=20200915");
        background-size: 8px;
        background-repeat: no-repeat;
        opacity: 0; }
    form .form-group .form-check input[type=checkbox]:checked + label::before {
      background-color: #0097A7;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    form .form-group .form-check input[type=checkbox]:checked + label::after {
      opacity: 1; }
  form .form-group .form-check input[type=radio] {
    opacity: 0; }
    form .form-group .form-check input[type=radio] + label {
      cursor: pointer; }
      form .form-group .form-check input[type=radio] + label::before {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 5px;
        left: -1px;
        content: "";
        border: 1px solid #C6C6C8;
        border-radius: 10rem;
        background-color: #FFFFFF; }
      form .form-group .form-check input[type=radio] + label::after {
        position: absolute;
        width: 5px;
        height: 5px;
        top: 10.5px;
        left: 4.5px;
        content: "";
        background-color: #FFFFFF;
        border-radius: 10rem;
        box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.28);
        opacity: 0; }
    form .form-group .form-check input[type=radio]:checked + label::before {
      background-color: #0097A7;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    form .form-group .form-check input[type=radio]:checked + label::after {
      opacity: 1; }

.frame {
  position: relative;
  padding: 2rem;
  margin-top: calc(1rem + 18px);
  margin-bottom: 1rem;
  text-align: left;
  border: 2px solid #0097A7;
  background-color: #FFFFFF; }
  .frame .label {
    position: absolute;
    top: -18px;
    left: 1rem;
    padding: 0.375rem 2rem;
    color: #FFFFFF;
    background-color: #0097A7;
    border-radius: 10rem;
    font-size: 14px; }

#header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #C6C6C8; }
  #header .header-btn .btn {
    margin: 0.25rem 0; }
  #header .nav-item {
    margin: 0.25rem 0;
    font-size: 14px; }
    #header .nav-item .nav-link {
      color: rgba(60, 60, 67, 0.6);
      padding: 0 0.5rem;
      border-radius: 0.25rem; }
      #header .nav-item .nav-link:hover {
        background-color: #EFEFF4; }
    #header .nav-item.dropdown .dropdown-menu {
      border: 1px solid #C6C6C8;
      font-size: 14px;
      margin-top: 0; }
      #header .nav-item.dropdown .dropdown-menu .dropdown-item {
        color: rgba(60, 60, 67, 0.6); }
        #header .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
          background-color: #EFEFF4; }
    #header .nav-item.dropdown:hover .dropdown-menu {
      display: block; }
  #header .navbar-brand {
    height: 48px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-right: 0; }
    #header .navbar-brand img {
      height: 34px; }
    #header .navbar-brand .logo {
      display: inline; }
  #header .navbar-subbrand {
    display: flex;
    align-items: center; }
    #header .navbar-subbrand .separator {
      display: none;
      height: 34px;
      border-left: 1px solid #C6C6C8;
      margin: 0 0.5rem; }
    #header .navbar-subbrand .nav-item {
      line-height: 2; }
      #header .navbar-subbrand .nav-item .nav-link {
        font-weight: bold;
        color: #3C3C43; }
      #header .navbar-subbrand .nav-item.dropdown .dropdown-menu .dropdown-item {
        font-weight: bold;
        color: #3C3C43; }
  #header a.navbar-toggler {
    width: 1rem;
    color: rgba(60, 60, 67, 0.6); }
    #header a.navbar-toggler[aria-expanded="true"] .collapse-icon {
      display: inline;
      margin-left: 1.685px; }
    #header a.navbar-toggler[aria-expanded="true"] .expand-icon {
      display: none; }
    #header a.navbar-toggler[aria-expanded="false"] .collapse-icon {
      display: none; }
    #header a.navbar-toggler[aria-expanded="false"] .expand-icon {
      display: inline; }
  #header .navbar-collapse {
    height: calc(64px - 1rem); }
    #header .navbar-collapse.show {
      height: calc(100vh - 64px); }
  #header.secondary {
    background-color: #EFEFF4; }
    #header.secondary .nav-item .nav-link:hover {
      background-color: #D7D7DB; }
    #header.secondary .nav-item.dropdown .dropdown-menu {
      background-color: #EFEFF4; }
      #header.secondary .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
        background-color: #D7D7DB; }
  @media (min-width: 768px) {
    #header .header-btn .btn {
      margin: 0 0.25rem; }
    #header .nav-item {
      margin: 0 0.25rem;
      font-size: 16px; }
      #header .nav-item.dropdown .dropdown-menu {
        font-size: 16px; }
    #header .navbar-brand {
      margin-right: 1rem; }
    #header .navbar-subbrand .separator {
      display: block; } }

img {
  max-width: 100%;
  height: auto; }
  img.figure-img {
    border-radius: 0; }
  img.img-fluid {
    width: 100%; }
  img.shadow {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important; }

a:hover img {
  opacity: 0.9; }

.img-hover-mask {
  position: relative; }
  .img-hover-mask .img-hover-mask-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; }
  .img-hover-mask:hover .img-hover-mask-content {
    opacity: 1; }

.modal .modal-dialog {
  margin: 0; }

.modal.fade .modal-dialog {
  transform: translate(0, 50px); }

.modal.show .modal-dialog {
  transform: none; }

.modal .modal-content {
  border-radius: 0;
  border: none; }
  .modal .modal-content .modal-body {
    padding: 0 0 1rem 0; }
    .modal .modal-content .modal-body .modal-image {
      margin-bottom: 1rem;
      margin-left: -15px;
      margin-right: -15px;
      min-width: calc(100% + 30px); }

.modal.modal-image .modal-content .modal-body {
  padding: 0; }
  .modal.modal-image .modal-content .modal-body .modal-image {
    margin-bottom: 0; }

.modal button.close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #EFEFF4; }

@media (min-width: 768px) {
  .modal .modal-dialog {
    margin: 1.75rem auto; }
  .modal .modal-content .modal-body {
    padding: 1rem 1px; }
    .modal .modal-content .modal-body .modal-image {
      margin: 0;
      min-width: auto; }
  .modal button.close {
    background-color: rgba(118, 118, 128, 0.12); } }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px); }
  .modal-backdrop.show {
    opacity: 1; }

.nav-bar.section {
  padding-top: 0;
  padding-bottom: 0; }

.nav-bar .nav-bar-content {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

@media (min-width: 768px) {
  .nav-bar .nav-bar-content {
    font-size: 16px; } }

.page-header {
  position: relative;
  min-height: 50vh;
  color: #FFFFFF; }
  .page-header a {
    color: #FFFFFF; }
    .page-header a:hover {
      color: #FFFFFF; }
  .page-header::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.25); }
  .page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .page-header .nav-bar-content {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page-header .page-header-content {
    min-height: calc(50vh - 64px);
    display: flex;
    align-items: center; }

@media (min-width: 768px) {
  .page-header .nav-bar-content {
    font-size: 16px; }
  .page-header .page-header-content .description {
    width: 60%; } }

.table {
  color: #000000; }
  .table th,
  .table td {
    line-height: 2;
    border-top: 1px solid #C6C6C8;
    border-bottom: 1px solid #C6C6C8; }
  .table thead th {
    border-bottom: 1px solid #C6C6C8; }
  .table.border-0 th,
  .table.border-0 td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    border: none; }

.more:not(.collapsed) {
  display: none; }

p,
ol li,
ul li {
  line-height: 2; }

ol.list-style-type-bracket {
  list-style-type: none; }
  ol.list-style-type-bracket > li {
    position: relative;
    counter-increment: counter; }
    ol.list-style-type-bracket > li::before {
      position: absolute;
      left: -25px;
      content: "(" counter(counter) ")"; }

@counter-style circled {
  system: numeric;
  symbols: '' '①' '②' '③' '④' '⑤' '⑥' '⑦' '⑧' '⑨' '⑩';
  suffix: ""; }

ol.circled-list {
  list-style: none;
  counter-reset: list-counter; }
  ol.circled-list > li {
    position: relative;
    counter-increment: list-counter; }
    ol.circled-list > li::before {
      position: absolute;
      left: -25px;
      content: counter(list-counter, circled); }

ol.list-style-type-circle {
  list-style-type: none; }
  ol.list-style-type-circle > li {
    position: relative;
    counter-increment: counter; }
    ol.list-style-type-circle > li::before {
      position: absolute;
      left: -17px;
      content: counter(counter); }
    ol.list-style-type-circle > li::after {
      position: absolute;
      top: 8px;
      left: -22px;
      content: "";
      width: 18px;
      height: 18px;
      border: 1px solid;
      border-radius: 10rem; }

ul.list-style-type-disc {
  list-style-type: disc; }

a {
  color: #0097A7; }
  a:hover {
    color: #0097A7; }

h1 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 34px;
  font-weight: bold;
  line-height: 1.5; }

h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5; }

h3 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5; }

h4 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5; }

h5 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5; }

h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5; }

.text-body {
  color: #000000 !important; }

.text-primary {
  color: #0097A7 !important; }

.text-secondary {
  color: rgba(60, 60, 67, 0.6) !important; }

.text-accent {
  color: #FF9800; }

.text-success {
  color: #43A047 !important; }

.text-danger {
  color: #EF5350 !important; }

.font-family-heading {
  font-family: "Noto Sans JP", sans-serif; }

.font-size-base {
  font-size: 16px; }

.font-size-lg {
  font-size: 20px; }

.font-size-md {
  font-size: 18px; }

.font-size-sm {
  font-size: 14px; }

.line-height-base {
  line-height: 1.5; }

.line-height-lg {
  line-height: 2; }

.line-height-sm {
  line-height: 1.2; }

.title {
  position: relative; }
  .title::before {
    position: absolute;
    margin-left: -1rem;
    content: "";
    width: 0.5rem;
    height: 100%;
    background-color: #0097A7; }

.figure-caption {
  text-align: center;
  font-size: 14px;
  color: rgba(60, 60, 67, 0.6); }

.note {
  background-color: #FAFAFA;
  padding: 2rem;
  margin-bottom: 1rem;
  text-align: left; }

ul.annotation {
  padding-left: 0;
  list-style: none; }
  ul.annotation li {
    font-size: 14px;
    color: rgba(60, 60, 67, 0.6); }

hr {
  border-top: 1px solid #C6C6C8; }

mark {
  color: inherit;
  background: linear-gradient(transparent 50%, rgba(255, 193, 7, 0.5) 50% 85%, transparent 85%); }

@media (min-width: 768px) {
  h1 {
    font-size: 40px; }
  h2 {
    font-size: 34px; }
  h3 {
    font-size: 28px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 20px; }
  h6 {
    font-size: 16px; } }

.figure-video {
  margin-bottom: 0.5rem; }

.about-page .member {
  margin-bottom: 2rem; }
  .about-page .member img {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    border-radius: 50%; }
  .about-page .member .member-heading {
    font-size: 14px;
    color: #0097A7; }
  .about-page .member .member-title {
    color: #000000;
    font-weight: bold;
    font-size: 16px; }
  .about-page .member a .member-link {
    font-size: 14px;
    color: rgba(60, 60, 67, 0.6); }
  .about-page .member a:hover {
    text-decoration: none; }
    .about-page .member a:hover .member-link {
      color: #0097A7;
      text-decoration: underline; }

.case-page .case-page-nav-bar.section {
  padding-top: 0;
  padding-bottom: 0; }

.case-page .case-page-nav-bar .case-page-nav-bar-content {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.case-page .case-page-header {
  min-height: calc(50vh - 64px);
  background-color: #FAFAFA; }
  .case-page .case-page-header.offset-header {
    margin-top: 64px; }
  .case-page .case-page-header .page-header-image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-size: cover;
    background-position: center center;
    min-height: calc(50vh - 64px - 2rem * 2); }

.case-page .heading {
  margin-top: calc(100px - 64px);
  margin-bottom: 2rem;
  padding-top: 64px; }
  .case-page .heading:first-child {
    margin-top: -64px;
    padding-top: 64px; }
  .case-page .heading.top-heading {
    margin-top: -64px;
    padding-top: 64px; }

.case-page .name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0; }

.case-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .case-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -43px;
    left: 0; }

@media (min-width: 768px) {
  .case-page {
    font-size: 18px; }
    .case-page .case-page-nav-bar .case-page-nav-bar-content {
      font-size: 16px; } }

.event-page {
  color: #3C3C43; }
  .event-page .event-page-header.offset-header {
    margin-top: 64px; }
  .event-page .contents {
    border: 1px solid #C6C6C8;
    padding: 1rem; }
    .event-page .contents .contents-title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0; }
  .event-page .list-member {
    background-color: #F5F5F5;
    padding: 1rem; }
    .event-page .list-member:not(:last-child) {
      margin-bottom: 1rem; }
    .event-page .list-member img {
      margin-bottom: 1rem;
      border-radius: 10rem; }
    .event-page .list-member .list-title {
      margin-top: 0;
      margin-bottom: 0; }
    .event-page .list-member .list-body {
      margin-top: 1rem;
      font-size: 14px; }
      .event-page .list-member .list-body p:last-child {
        margin-bottom: 0; }
  .event-page .book {
    background-color: #F5F5F5;
    padding: 1rem; }
    .event-page .book img {
      margin-bottom: 1rem;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
    .event-page .book .book-title {
      margin-top: 0;
      margin-bottom: 0; }
    .event-page .book .book-body {
      margin-top: 1rem;
      font-size: 14px; }
  .event-page .form {
    position: relative;
    background-color: #EFFCFE;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem; }
    .event-page .form .form-header {
      position: absolute;
      width: 100%;
      text-align: center;
      top: -35px;
      left: 0; }
  @media (min-width: 768px) {
    .event-page .contents {
      padding: 2rem; }
    .event-page .list-member {
      padding: 2rem; }
    .event-page .book {
      padding: 2rem; }
    .event-page .form {
      padding-top: 4rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      padding-right: 2rem; }
      .event-page .form .form-header {
        top: -43px; } }

.pj-one-day-training-202403-page .pj-one-day-training-202403-page-header {
  min-height: 50vh; }
  .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header .page-header-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header .page-header-content .page-title h4 {
      margin-bottom: 0; }
    .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header .page-header-content .page-title h2 {
      margin-top: 0; }
    .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.pj-one-day-training-202403-page .targets {
  text-align: center; }
  .pj-one-day-training-202403-page .targets .target-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pj-one-day-training-202403-page .targets .target-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #FFFFFF;
      border-radius: 50%; }
    .pj-one-day-training-202403-page .targets .target-icon img {
      position: absolute;
      height: 100%; }

.pj-one-day-training-202403-page .effects {
  text-align: center; }
  .pj-one-day-training-202403-page .effects .effect-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pj-one-day-training-202403-page .effects .effect-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #FFFFFF;
      border-radius: 50%; }
    .pj-one-day-training-202403-page .effects .effect-icon img {
      position: absolute;
      height: 100%; }

.pj-one-day-training-202403-page .list-member {
  background-color: #FAFAFA;
  padding: 2rem; }
  .pj-one-day-training-202403-page .list-member:not(:last-child) {
    margin-bottom: 2rem; }
  .pj-one-day-training-202403-page .list-member img {
    margin-bottom: 1rem; }
  .pj-one-day-training-202403-page .list-member .list-title {
    margin-top: 0;
    text-align: center; }
  .pj-one-day-training-202403-page .list-member .list-heading {
    color: #0097A7; }
  .pj-one-day-training-202403-page .list-member .list-body {
    font-size: 14px; }

.pj-one-day-training-202403-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .pj-one-day-training-202403-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -35px;
    left: 0; }

.pj-one-day-training-202403-page .section .section-header {
  margin-bottom: 4rem; }

@media (min-width: 768px) {
  .pj-one-day-training-202403-page .pj-one-day-training-202403-page-header .page-header-image {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 50vh;
    min-width: calc(100vw / 2 - 15px); }
  .pj-one-day-training-202403-page .list-member .list-title {
    text-align: left; }
  .pj-one-day-training-202403-page .form .form-header {
    top: -43px; } }

.events-page .card-event-coming-soon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem; }
  .events-page .card-event-coming-soon::before {
    width: 100%;
    padding-top: 56.25%;
    content: "";
    background-color: #EFEFF4; }
  .events-page .card-event-coming-soon .card-text {
    position: absolute;
    text-align: center; }

.events-page .list-voice {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px; }
  .events-page .list-voice:not(:last-child) {
    margin-bottom: 2rem; }
  .events-page .list-voice .user-icon {
    width: 64px;
    height: 64px;
    background-color: #EFFCFE;
    border-radius: 50%;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .events-page .list-voice .user-icon img {
      height: 80%; }

.nandemo-page .nandemo-page-nav-bar.section {
  padding-top: 0;
  padding-bottom: 0; }

.nandemo-page .nandemo-page-nav-bar .nandemo-page-nav-bar-content {
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.nandemo-page .nandemo-page-header.offset-header {
  margin-top: 64px; }

.nandemo-page .heading {
  margin-top: calc(100px - 64px);
  margin-bottom: 2rem;
  padding-top: 64px; }
  .nandemo-page .heading:first-child {
    margin-top: -64px;
    padding-top: 64px; }
  .nandemo-page .heading.top-heading {
    margin-top: -64px;
    padding-top: 64px; }

@media (min-width: 768px) {
  .nandemo-page {
    font-size: 18px; }
    .nandemo-page .nandemo-page-nav-bar .nandemo-page-nav-bar-content {
      font-size: 16px; } }

.pejite-page .prize {
  position: relative;
  display: inline-block;
  padding-left: calc(26px + 1rem);
  padding-right: calc(26px + 1rem); }
  .pejite-page .prize .text {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; }
  .pejite-page .prize::before {
    position: absolute;
    width: 26px;
    height: 72px;
    top: 0;
    left: 0;
    content: "";
    background-image: url("/assets/img/lab/pejite/prize-left.svg?t=20220503");
    background-size: 26px;
    background-repeat: no-repeat; }
  .pejite-page .prize::after {
    position: absolute;
    width: 26px;
    height: 72px;
    top: 0;
    right: 0;
    content: "";
    background-image: url("/assets/img/lab/pejite/prize-right.svg?t=20220503");
    background-size: 26px;
    background-repeat: no-repeat; }

.pejite-page .scenes .scene-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pejite-page .scenes .scene-icon::before {
    width: 50%;
    padding-top: 50%;
    content: "";
    background-color: #FFFFFF;
    border-radius: 50%; }
  .pejite-page .scenes .scene-icon img {
    position: absolute;
    height: 60%; }

.pejite-page .list-voice {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px; }
  .pejite-page .list-voice:not(:last-child) {
    margin-bottom: 2rem; }
  .pejite-page .list-voice .user-icon {
    width: 64px;
    height: 64px;
    background-color: #EFFCFE;
    border-radius: 50%;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pejite-page .list-voice .user-icon img {
      height: 80%; }

@media (min-width: 768px) {
  .pejite-page .prize {
    padding-left: calc(30px + 1rem);
    padding-right: calc(30px + 1rem); }
    .pejite-page .prize::before {
      width: 30px;
      height: 84px;
      background-size: 30px; }
    .pejite-page .prize::after {
      width: 30px;
      height: 84px;
      background-size: 30px; } }

.lab-page .section-link {
  margin-bottom: 1rem; }
  .lab-page .section-link.pickup-news {
    background-color: #EF5350; }
  .lab-page .section-link.pickup-books {
    background-color: #FF9800; }
  .lab-page .section-link.learnings {
    background-color: #1E88E5; }
  .lab-page .section-link.downloads {
    background-color: #0097A7; }
  .lab-page .section-link a {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 4rem;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center; }
    .lab-page .section-link a .section-link-icon {
      font-size: 40px; }
    .lab-page .section-link a .section-link-arrow {
      position: absolute;
      left: calc(50% - 8.75px);
      bottom: 2rem;
      font-size: 20px;
      transition: 0.5s; }
    .lab-page .section-link a:hover {
      text-decoration: none; }
      .lab-page .section-link a:hover .section-link-arrow {
        bottom: 1rem; }

.lab-page .list-pickup-news .list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }
  .lab-page .list-pickup-news .list-header .news-category {
    width: 80px; }
    .lab-page .list-pickup-news .list-header .news-category.category-news {
      background-color: #1E88E5; }
    .lab-page .list-pickup-news .list-header .news-category.category-event {
      background-color: #43A047; }
    .lab-page .list-pickup-news .list-header .news-category.category-knowledge {
      background-color: #7986CB; }
    .lab-page .list-pickup-news .list-header .news-category.category-other {
      background-color: #8A8A8E; }
  .lab-page .list-pickup-news .list-header .list-date {
    font-size: 14px;
    color: rgba(60, 60, 67, 0.6); }

.lab-page .list-pickup-news .news-source {
  font-size: 14px;
  color: rgba(60, 60, 67, 0.6); }

.lab-page .list-pickup-news .list-comment {
  font-size: 14px;
  background-color: #FAFAFA;
  padding: 0.5rem 1rem; }

.lab-page .list-pickup-book img {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }

.lab-page .list-pickup-book .list-header {
  text-align: center; }
  .lab-page .list-pickup-book .list-header .list-date {
    display: inline-block;
    font-size: 14px;
    color: rgba(60, 60, 67, 0.6);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }

.lab-page .list-pickup-book .list-title {
  margin-top: 0;
  margin-bottom: 0; }

.lab-page .list-pickup-book .list-comment {
  font-size: 14px;
  background-color: #FAFAFA;
  padding: 0.5rem 1rem; }

.lab-page .list-learning {
  background-color: #FFFFFF;
  padding: 2rem;
  margin-bottom: 1rem; }
  .lab-page .list-learning img {
    margin-bottom: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .lab-page .list-learning .list-header {
    display: block; }
    .lab-page .list-learning .list-header .link {
      white-space: nowrap; }

.lab-page .list-download {
  background-color: #FFFFFF;
  padding: 2rem;
  margin-bottom: 1rem; }
  .lab-page .list-download img {
    margin-bottom: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .lab-page .list-download .list-header {
    display: block; }
    .lab-page .list-download .list-header .link {
      white-space: nowrap; }

.lab-page .list-member {
  background-color: #FFFFFF;
  padding: 2rem; }
  .lab-page .list-member:not(:last-child) {
    margin-bottom: 1rem; }
  .lab-page .list-member img {
    margin-bottom: 1rem; }
  .lab-page .list-member .list-title {
    margin-top: 0;
    margin-bottom: 0; }

@media (min-width: 768px) {
  .lab-page .list-pickup-news .list-header {
    display: block;
    text-align: center; }
  .lab-page .list-learning img {
    margin-bottom: 0; }
  .lab-page .list-learning .list-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .lab-page .list-learning .list-header .list-title {
      margin-top: 0;
      margin-bottom: 0; }
  .lab-page .list-download img {
    margin-bottom: 0; }
  .lab-page .list-download .list-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
    .lab-page .list-download .list-header .list-title {
      margin-top: 0;
      margin-bottom: 0; } }

.list-news .list-date {
  color: rgba(60, 60, 67, 0.6); }

.list-news .news-category {
  width: 80px; }
  .list-news .news-category.category-web {
    background-color: #1E88E5; }
  .list-news .news-category.category-blog {
    background-color: #43A047; }
  .list-news .news-category.category-comment {
    background-color: #7986CB; }
  .list-news .news-category.category-event {
    background-color: #EF5350; }
  .list-news .news-category.category-presentation {
    background-color: #FFC107; }
  .list-news .news-category.category-info {
    background-color: #FF9800; }

.balus-page .balus-page-header.offset-header {
  margin-top: 64px; }

.balus-page .fv_company_banner {
  background: #f4f4f4;
  padding: 2rem 0; }
  .balus-page .fv_company_banner .list_banner {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em;
    list-style: none;
    padding: 0; }
    @media screen and (max-width: 768px) {
      .balus-page .fv_company_banner .list_banner {
        display: grid;
        grid-template-columns: repeat(3, 1fr); } }
    .balus-page .fv_company_banner .list_banner li {
      max-width: 200px; }

.balus-page .problems {
  text-align: center; }
  .balus-page .problems .problem-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .balus-page .problems .problem-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #FFFFFF;
      border-radius: 50%; }
    .balus-page .problems .problem-icon img {
      position: absolute;
      height: 100%; }
  .balus-page .problems .message {
    position: relative;
    display: inline-block; }
    .balus-page .problems .message::before {
      position: absolute;
      bottom: -2px;
      left: -20px;
      content: "";
      width: 3px;
      height: calc(100% + 2px);
      background: #000000;
      transform: rotate(-30deg); }
    .balus-page .problems .message::after {
      position: absolute;
      bottom: -2px;
      right: -20px;
      content: "";
      width: 3px;
      height: calc(100% + 2px);
      background: #000000;
      transform: rotate(30deg); }
  .balus-page .problems .solution-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .balus-page .problems .solution-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #EFFCFE;
      border-radius: 50%; }
    .balus-page .problems .solution-icon img {
      position: absolute;
      height: 100%; }

.balus-page .description .message {
  position: relative;
  display: inline-block;
  color: #0097A7; }
  .balus-page .description .message::before {
    position: absolute;
    bottom: -2px;
    left: -20px;
    content: "";
    width: 3px;
    height: calc(100% + 2px);
    background: #0097A7;
    transform: rotate(-30deg); }
  .balus-page .description .message::after {
    position: absolute;
    bottom: -2px;
    right: -20px;
    content: "";
    width: 3px;
    height: calc(100% + 2px);
    background: #0097A7;
    transform: rotate(30deg); }

.balus-page .features .feature-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .balus-page .features .feature-icon::before {
    width: 50%;
    padding-top: 50%;
    content: "";
    background-color: #FFFFFF;
    border-radius: 50%; }
  .balus-page .features .feature-icon img {
    position: absolute;
    height: 100%; }

.balus-page .form {
  text-align: center; }

.balus-page .section .section-header {
  margin-bottom: 4rem; }

.balus-page .price .form {
  text-align: left;
  padding-bottom: 64px; }

.balus-page .price #form {
  background: #FAFAFA !important; }
  .balus-page .price #form .form-header {
    text-align: center; }

.dx-series-page .dx-series-page-header {
  min-height: 50vh; }
  .dx-series-page .dx-series-page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .dx-series-page .dx-series-page-header .page-header-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .dx-series-page .dx-series-page-header .page-header-content .page-title h4 {
      margin-bottom: 0; }
    .dx-series-page .dx-series-page-header .page-header-content .page-title h2 {
      margin-top: 0; }
    .dx-series-page .dx-series-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.dx-series-page .problems {
  text-align: center; }
  .dx-series-page .problems .problem-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .dx-series-page .problems .problem-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #FFFFFF;
      border-radius: 50%; }
    .dx-series-page .problems .problem-icon img {
      position: absolute;
      height: 100%; }

.dx-series-page .list-voice {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px;
  height: 100%; }

.dx-series-page .content:not(:last-child) {
  margin-bottom: 2rem; }

.dx-series-page .content .content-title {
  color: #FFFFFF;
  text-align: center;
  background-color: #0097A7;
  padding: 1rem; }

.dx-series-page .content .content-body {
  font-size: 14px; }

.dx-series-page .customers .customer-logo {
  padding-left: 1rem;
  padding-right: 1rem; }

.dx-series-page .list-member {
  background-color: #FFFFFF;
  padding: 2rem; }
  .dx-series-page .list-member:not(:last-child) {
    margin-bottom: 2rem; }
  .dx-series-page .list-member img {
    margin-bottom: 1rem; }
  .dx-series-page .list-member .list-title {
    margin-top: 0;
    text-align: center; }
  .dx-series-page .list-member .list-body {
    font-size: 14px; }

.dx-series-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .dx-series-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -35px;
    left: 0; }

.dx-series-page .section .section-header {
  margin-bottom: 4rem; }

@media (min-width: 768px) {
  .dx-series-page .dx-series-page-header .page-header-image {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 50vh;
    min-width: calc(100vw / 2 - 15px); }
  .dx-series-page .list-member .list-title {
    text-align: left; }
  .dx-series-page .form .form-header {
    top: -43px; } }

.free-consultation-page .free-consultation-page-header {
  position: relative;
  color: #FFFFFF; }
  .free-consultation-page .free-consultation-page-header a {
    color: #FFFFFF; }
  .free-consultation-page .free-consultation-page-header::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.25); }
  .free-consultation-page .free-consultation-page-header.offset-header {
    margin-top: 64px; }
  .free-consultation-page .free-consultation-page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .free-consultation-page .free-consultation-page-header .page-header-content {
    padding-top: 100px;
    padding-bottom: 2rem; }
    .free-consultation-page .free-consultation-page-header .page-header-content .page-title {
      text-align: center;
      margin-bottom: 1rem; }
      .free-consultation-page .free-consultation-page-header .page-header-content .page-title h3 {
        width: 100%;
        display: inline-block;
        background-color: #FFFFFF;
        color: #000000;
        padding-top: 0.5rem;
        padding-bottom: 1px;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0;
        margin-bottom: -1px; }
      .free-consultation-page .free-consultation-page-header .page-header-content .page-title h1 {
        width: 100%;
        display: inline-block;
        background-color: #FFFFFF;
        color: #000000;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 40px; }
    .free-consultation-page .free-consultation-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.free-consultation-page .problems .problem-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .free-consultation-page .problems .problem-icon::before {
    width: 50%;
    padding-top: 50%;
    content: "";
    background-color: #FAFAFA;
    border-radius: 50%; }
  .free-consultation-page .problems .problem-icon img {
    position: absolute;
    height: 60%; }

.free-consultation-page .list-process {
  border-radius: 10px;
  background-color: #FAFAFA;
  padding: 2rem; }
  .free-consultation-page .list-process img {
    margin-bottom: 1rem; }
  .free-consultation-page .list-process:not(:last-child) {
    margin-bottom: 2rem; }

.free-consultation-page .list-member {
  background-color: #FFFFFF;
  padding: 2rem;
  margin-bottom: 1rem;
  height: calc(100% - 1rem); }
  .free-consultation-page .list-member img {
    margin-bottom: 1rem; }
  .free-consultation-page .list-member .list-title {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; }
  .free-consultation-page .list-member .list-body {
    font-size: 14px; }

.free-consultation-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .free-consultation-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -56px;
    left: 0; }

@media (min-width: 768px) {
  .free-consultation-page .free-consultation-page-header .page-header-content .page-title {
    text-align: left; }
    .free-consultation-page .free-consultation-page-header .page-header-content .page-title h3 {
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem; }
    .free-consultation-page .free-consultation-page-header .page-header-content .page-title h1 {
      width: auto;
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 52px; }
  .free-consultation-page .free-consultation-page-header .page-header-content .description {
    margin-top: 2rem;
    width: 60%; }
  .free-consultation-page .form .form-header {
    top: -43px; } }

.sales-series-page .sales-series-page-header {
  min-height: 50vh; }
  .sales-series-page .sales-series-page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .sales-series-page .sales-series-page-header .page-header-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .sales-series-page .sales-series-page-header .page-header-content .page-title h4 {
      margin-bottom: 0; }
    .sales-series-page .sales-series-page-header .page-header-content .page-title h2 {
      margin-top: 0; }
    .sales-series-page .sales-series-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.sales-series-page .problems {
  text-align: center; }
  .sales-series-page .problems .problem-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .sales-series-page .problems .problem-icon::before {
      width: 50%;
      padding-top: 50%;
      content: "";
      background-color: #FFFFFF;
      border-radius: 50%; }
    .sales-series-page .problems .problem-icon img {
      position: absolute;
      height: 100%; }

.sales-series-page .list-voice {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px;
  height: 100%; }

.sales-series-page .content:not(:last-child) {
  margin-bottom: 2rem; }

.sales-series-page .content .content-title {
  color: #FFFFFF;
  text-align: center;
  background-color: #0097A7;
  padding: 1rem; }

.sales-series-page .content .content-body {
  font-size: 14px; }

.sales-series-page .balus .message {
  position: relative;
  display: inline-block;
  color: #0097A7; }
  .sales-series-page .balus .message::before {
    position: absolute;
    bottom: -2px;
    left: -20px;
    content: "";
    width: 3px;
    height: calc(100% + 2px);
    background: #0097A7;
    transform: rotate(-30deg); }
  .sales-series-page .balus .message::after {
    position: absolute;
    bottom: -2px;
    right: -20px;
    content: "";
    width: 3px;
    height: calc(100% + 2px);
    background: #0097A7;
    transform: rotate(30deg); }

.sales-series-page .list-member {
  background-color: #FFFFFF;
  padding: 2rem; }
  .sales-series-page .list-member:not(:last-child) {
    margin-bottom: 2rem; }
  .sales-series-page .list-member img {
    margin-bottom: 1rem; }
  .sales-series-page .list-member .list-title {
    margin-top: 0;
    text-align: center; }
  .sales-series-page .list-member .list-body {
    font-size: 14px; }

.sales-series-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .sales-series-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -35px;
    left: 0; }

.sales-series-page .section .section-header {
  margin-bottom: 4rem; }

@media (min-width: 768px) {
  .sales-series-page .sales-series-page-header .page-header-image {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 50vh;
    min-width: calc(100vw / 2 - 15px); }
  .sales-series-page .list-member .list-title {
    text-align: left; }
  .sales-series-page .form .form-header {
    top: -43px; } }

.se-01-page .se-01-page-header {
  min-height: 50vh; }
  .se-01-page .se-01-page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .se-01-page .se-01-page-header .page-header-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .se-01-page .se-01-page-header .page-header-content .page-title h3 {
      margin-bottom: 0; }
    .se-01-page .se-01-page-header .page-header-content .page-title h1 {
      margin-top: 0; }
    .se-01-page .se-01-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.se-01-page .effects .effect-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .se-01-page .effects .effect-icon::before {
    width: 50%;
    padding-top: 50%;
    content: "";
    background-color: #FFFFFF;
    border-radius: 50%; }
  .se-01-page .effects .effect-icon img {
    position: absolute;
    height: 100%; }

.se-01-page .list-member {
  background-color: #FFFFFF;
  padding: 2rem; }
  .se-01-page .list-member:not(:last-child) {
    margin-bottom: 2rem; }
  .se-01-page .list-member img {
    margin-bottom: 1rem; }
  .se-01-page .list-member .list-title {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center; }
  .se-01-page .list-member .list-heading {
    margin-top: 0;
    color: #0097A7; }
  .se-01-page .list-member .list-body {
    font-size: 14px; }

.se-01-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .se-01-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -35px;
    left: 0; }

.se-01-page .section .section-header {
  margin-bottom: 4rem; }

@media (min-width: 768px) {
  .se-01-page .se-01-page-header .page-header-image {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-width: calc(100vw / 2 - 15px); }
  .se-01-page .list-member .list-title {
    text-align: left; }
  .se-01-page .form .form-header {
    top: -43px; } }

.training-sw-basic-page .training-sw-basic-page-header {
  min-height: 50vh; }
  .training-sw-basic-page .training-sw-basic-page-header.offset-header {
    min-height: calc(50vh - 64px);
    margin-top: 64px; }
  .training-sw-basic-page .training-sw-basic-page-header .page-header-content {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px; }
    .training-sw-basic-page .training-sw-basic-page-header .page-header-content .page-title h2 {
      margin-bottom: 0; }
    .training-sw-basic-page .training-sw-basic-page-header .page-header-content .page-title h1 {
      margin-top: 0; }
    .training-sw-basic-page .training-sw-basic-page-header .page-header-content .description {
      margin-bottom: 2rem; }

.training-sw-basic-page .problems .problem-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .training-sw-basic-page .problems .problem-icon::before {
    width: 50%;
    padding-top: 50%;
    content: "";
    background-color: #FFFFFF;
    border-radius: 50%; }
  .training-sw-basic-page .problems .problem-icon img {
    position: absolute;
    height: 60%; }

.training-sw-basic-page .contents {
  position: relative;
  background-color: #FAFAFA;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .training-sw-basic-page .contents .contents-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -43px;
    left: 0; }
  .training-sw-basic-page .contents .contents-element img {
    margin-bottom: 1rem; }
  .training-sw-basic-page .contents .contents-plus {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px; }

.training-sw-basic-page .list-flow {
  position: relative; }
  .training-sw-basic-page .list-flow:not(:last-child) {
    margin-bottom: 8rem; }
  .training-sw-basic-page .list-flow::before {
    position: absolute;
    width: 1000%;
    height: 100%;
    top: -1rem;
    left: -15px;
    content: "";
    background-color: #FAFAFA; }
  .training-sw-basic-page .list-flow img {
    margin-bottom: 2rem; }
  .training-sw-basic-page .list-flow p {
    line-height: 1.5; }

.training-sw-basic-page .flow-floating-image-left {
  position: relative; }
  .training-sw-basic-page .flow-floating-image-left img {
    position: absolute;
    width: 160px;
    top: calc(-2rem * 4 - 2rem);
    left: calc(50% - 80px);
    z-index: 1; }

.training-sw-basic-page .flow-floating-image-right {
  position: relative; }
  .training-sw-basic-page .flow-floating-image-right img {
    position: absolute;
    width: 160px;
    top: calc(-2rem * 4 - 2rem);
    right: calc(50% - 80px);
    z-index: 1; }

.training-sw-basic-page .list-voice {
  background-color: #FFFFFF;
  padding: 2rem;
  border-radius: 10px; }
  .training-sw-basic-page .list-voice:not(:last-child) {
    margin-bottom: 2rem; }
  .training-sw-basic-page .list-voice .user-icon {
    width: 64px;
    height: 64px;
    background-color: #EFFCFE;
    border-radius: 50%;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    .training-sw-basic-page .list-voice .user-icon img {
      height: 80%; }

.training-sw-basic-page .list-faq {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }
  .training-sw-basic-page .list-faq:not(:last-child) {
    margin-bottom: 2rem; }
  .training-sw-basic-page .list-faq a {
    color: #000000;
    text-decoration: none; }
  .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="true"] .question {
    border-radius: 10px 10px 0 0; }
    .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="true"] .question .collapse-icon {
      display: block; }
    .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="true"] .question .expand-icon {
      display: none; }
  .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="false"] .question {
    border-radius: 10px; }
    .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="false"] .question .collapse-icon {
      display: none; }
    .training-sw-basic-page .list-faq .faq-toggler[aria-expanded="false"] .question .expand-icon {
      display: block; }
  .training-sw-basic-page .list-faq .question {
    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 2rem; }
    .training-sw-basic-page .list-faq .question .question-icon {
      position: relative;
      width: 64px;
      height: 64px;
      background-color: #EFFCFE;
      border-radius: 10rem;
      margin-right: 2rem; }
      .training-sw-basic-page .list-faq .question .question-icon::after {
        position: absolute;
        width: 64px;
        height: 64px;
        top: 0;
        left: 0;
        content: "Q";
        font-size: 22px;
        color: #0097A7;
        line-height: 64px;
        text-align: center; }
  .training-sw-basic-page .list-faq .answer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #FAFAFA;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: calc(2rem + 14px); }
    .training-sw-basic-page .list-faq .answer .answer-icon {
      position: relative;
      width: 64px;
      height: 64px;
      background-color: #FFFFFF;
      border-radius: 10rem;
      margin-right: 2rem; }
      .training-sw-basic-page .list-faq .answer .answer-icon::after {
        position: absolute;
        width: 64px;
        height: 64px;
        top: 0;
        left: 0;
        content: "A";
        font-size: 22px;
        color: #0097A7;
        line-height: 64px;
        text-align: center; }

.training-sw-basic-page .form {
  position: relative;
  background-color: #EFFCFE;
  padding-top: 4rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem; }
  .training-sw-basic-page .form .form-header {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -35px;
    left: 0; }

.training-sw-basic-page .section {
  overflow: hidden; }
  .training-sw-basic-page .section .section-header {
    margin-bottom: 4rem; }

@media (min-width: 768px) {
  .training-sw-basic-page .training-sw-basic-page-header .page-header-image {
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 50vh;
    min-width: calc(100vw / 2 - 15px); }
  .training-sw-basic-page .list-flow.image-left::before {
    right: auto;
    left: 40%; }
  .training-sw-basic-page .list-flow.image-right::before {
    right: 40%;
    left: auto; }
  .training-sw-basic-page .flow-floating-image-left img {
    top: -8rem;
    left: calc(25% - 80px); }
  .training-sw-basic-page .flow-floating-image-right img {
    top: calc(-2rem * 4 - 65px);
    right: calc(25% - 80px); }
  .training-sw-basic-page .form .form-header {
    top: -43px; } }

.master-of-work-balus-page {
  /* リンク */
  /* レイアウト */
  /* 見出し */
  /* メインビジュアル */
  /* CTA */
  /* カード */
  /* リスト */
  /* 思考の質が、仕事の質を決める。 */
  /* 構造化アプローチを実現する、三種の神器 */
  /* チーム／組織の構造化レベル */
  /* 構造化アプローチを、業務実装する「Balus」 */
  /* 課題 */
  /* 事例 */
  /* すべての企業が、複雑さを楽しめるように！ */
  /* フッター */
  /* スクロールバー */
  /* その他 */
  /* Slider */ }

@viewport {
  .master-of-work-balus-page {
    zoom: 1.0;
    width: extend-to-zoom; } }
  .master-of-work-balus-page * {
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-after: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  .master-of-work-balus-page html {
    -webkit-text-size-adjust: 100%;
    /* Prevent adjustments of font size after orientation changes in iOS. */ }
  .master-of-work-balus-page img {
    max-width: 100%; }
  .master-of-work-balus-page ul, .master-of-work-balus-page ol, .master-of-work-balus-page li {
    list-style: none; }
  .master-of-work-balus-page html, .master-of-work-balus-page body, .master-of-work-balus-page :root {
    font-size: 14px; }
  @media (min-width: 789px) {
    .master-of-work-balus-page html, .master-of-work-balus-page body, .master-of-work-balus-page :root {
      font-size: 16px; } }
  .master-of-work-balus-page body {
    font-family: 'Noto Sans JP', meiryo, sans-serif;
    background: #fff;
    color: #282B2D; }
  .master-of-work-balus-page h1, .master-of-work-balus-page h2, .master-of-work-balus-page h3, .master-of-work-balus-page h4, .master-of-work-balus-page h5, .master-of-work-balus-page h6 {
    font-size: 1rem;
    font-weight: normal; }
  .master-of-work-balus-page p {
    line-height: 1.6; }
  .master-of-work-balus-page a {
    text-decoration: none;
    transition: .2s; }
  .master-of-work-balus-page .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem; }
  @media (max-width: 800px) {
    .master-of-work-balus-page .container {
      padding: .7rem; } }
  .master-of-work-balus-page .sect {
    padding: 2rem 0; }
  .master-of-work-balus-page .sect_h {
    text-align: center;
    font-weight: 900;
    font-size: 1.6rem;
    margin: 0 0 2rem; }
  .master-of-work-balus-page .sect_h::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 3rem;
    height: 4px;
    border-radius: 4px;
    background: #FF9800; }
  .master-of-work-balus-page .sect_h--sub {
    text-align: center;
    font-size: 1.1rem;
    margin: 0 0 3rem; }
  .master-of-work-balus-page .mv {
    line-height: 0; }
  .master-of-work-balus-page .mv img {
    width: 100%; }
  .master-of-work-balus-page .cta {
    text-align: center;
    background: #F0F8F8;
    border-radius: 1rem;
    padding: 3rem 1rem;
    margin: 5rem 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }
  .master-of-work-balus-page .cta-text {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 2rem;
    color: #0097A7; }
  .master-of-work-balus-page .cta-btn {
    display: inline-block;
    min-width: 19rem;
    background: #FF9800;
    background-image: linear-gradient(45deg, #ffb548 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    color: #fff;
    font-weight: 900;
    padding: 1rem;
    border-radius: 9rem;
    transition: .3s, background 700ms ease-in-out; }
  @media (hover: hover) {
    .master-of-work-balus-page .cta-btn:hover {
      background-position: 0;
      transform: scale(1.1); } }
  .master-of-work-balus-page .card {
    background: #F8F8F8;
    border-radius: 10px;
    overflow: hidden;
    padding: 1rem 1rem 1rem 1.4rem;
    margin: 1rem 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }
  .master-of-work-balus-page .card::after {
    content: "";
    display: block;
    background: #67BCC5;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .master-of-work-balus-page .card .bold-h {
    margin: 0 0 .5rem; }
  .master-of-work-balus-page .dot-list {
    font-weight: bold; }
  .master-of-work-balus-page .dot-list li {
    padding: 0 .5rem; }
  .master-of-work-balus-page .dot-list li::before {
    content: "";
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 0 .5rem 0 0;
    background: #282B2D;
    transform: translateY(-50%);
    border-radius: 4px; }
  .master-of-work-balus-page .sect_01--h {
    font-weight: 900;
    font-size: 2rem;
    line-height: 1;
    padding: 0 0 0 3rem;
    margin: 0 0 2rem; }
  .master-of-work-balus-page .sect_01--h::before {
    content: "";
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2rem;
    height: 4px;
    border-radius: 4px;
    background: #FF9800; }
  .master-of-work-balus-page .strong-text {
    font-weight: 900;
    font-size: 1.2rem;
    color: #0097A7;
    margin: 0 0 1rem; }
  .master-of-work-balus-page .text_group {
    margin: 0 0 1rem; }
  .master-of-work-balus-page .text_group--img {
    line-height: 0; }
  .master-of-work-balus-page .qa_group--img {
    line-height: 0;
    flex: 0 0 20%;
    margin: 0 1.5rem 0 1rem;
    text-align: center; }
  @media (max-width: 1000px) {
    .master-of-work-balus-page .text_group--img {
      text-align: center;
      margin: 1rem 0; }
    .master-of-work-balus-page .text_group--img img {
      width: 400px;
      max-width: 60%; }
    .master-of-work-balus-page .qa_group {
      margin: 4rem 0 0; }
    .master-of-work-balus-page .qa_group--img {
      margin: 0 0 1rem; }
    .master-of-work-balus-page .qa_group--img img {
      width: 200px; } }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .text_group {
      display: flex; }
    .master-of-work-balus-page .text_group--img {
      flex: 0 0 28%;
      margin: 0 0 0 2rem;
      transform: translateY(-1rem); }
    .master-of-work-balus-page .qa_group {
      margin: 2rem 0;
      display: flex;
      align-items: center; } }
  .master-of-work-balus-page .steps {
    margin: 2rem 0; }
  .master-of-work-balus-page .steps--item_h2 {
    margin: auto;
    background: #fff;
    padding: .5rem;
    line-height: 1;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    width: 12rem;
    text-align: center;
    font-weight: bold;
    color: #6B6F72; }
  .master-of-work-balus-page .steps--item::after {
    content: ""; }
  .master-of-work-balus-page .steps--item img {
    width: 50%;
    display: block;
    margin: auto; }
  .master-of-work-balus-page .steps--item_h {
    background: #67BCC5;
    color: #fff;
    font-weight: 900;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 10px 10px 0 0; }
  .master-of-work-balus-page .steps--item_body {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }
  .master-of-work-balus-page .steps--item_body p {
    padding: 1rem; }
  @media (max-width: 1000px) {
    .master-of-work-balus-page .steps--item {
      margin: 2rem 0 3rem; }
    .master-of-work-balus-page .steps {
      margin: 4rem 0; } }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .steps {
      display: flex; }
    .master-of-work-balus-page .steps--item {
      flex: 1;
      padding: 0 1rem; }
    .master-of-work-balus-page .steps--item_body {
      min-height: 18rem; } }
  .master-of-work-balus-page .levels_group {
    margin: 3rem 0; }
  .master-of-work-balus-page .levels--h {
    font-size: 1.4rem;
    font-weight: 900;
    color: #FF9800;
    text-align: center;
    margin: 0 0 1rem;
    line-height: 1; }
  .master-of-work-balus-page .levels {
    margin: 3rem 0; }
  .master-of-work-balus-page .levels--item {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px; }
  .master-of-work-balus-page .levels--item::before {
    content: "";
    display: block;
    width: 100%;
    height: 10px;
    background: #FF9800;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0; }
  .master-of-work-balus-page .levels--item_h {
    font-weight: 900;
    margin: 0 0 .5rem; }
  .master-of-work-balus-page .levels--item .number {
    display: inline-block;
    font-weight: 900;
    background: #FF9800;
    color: #fff;
    line-height: 1;
    font-size: 1.2rem;
    padding: .4rem;
    border-radius: 10px 10px 0 0;
    text-align: center;
    width: 6rem;
    position: absolute;
    top: -1.4rem;
    left: 0; }
  .master-of-work-balus-page .levels--item_body {
    padding: 1.4rem 1rem 1rem; }
  .master-of-work-balus-page .levels--item_foot {
    background: #FFF4E4;
    padding: 1rem;
    border-radius: 0 0 10px 10px; }
  @media (max-width: 1000px) {
    .master-of-work-balus-page .levels--item {
      margin: 0 0 2rem; } }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .levels {
      display: flex; }
    .master-of-work-balus-page .levels--item {
      flex: 1;
      margin: 0 1rem; }
    .master-of-work-balus-page .levels--item_body {
      min-height: 10rem; }
    .master-of-work-balus-page .levels--item_foot {
      min-height: 7rem; } }
  .master-of-work-balus-page .balus-img {
    display: block;
    margin: 3rem auto;
    width: 700px;
    max-width: 90%; }
  .master-of-work-balus-page .bg-02 .card {
    background: #fff; }
  .master-of-work-balus-page .check_text {
    margin: 1rem 0 0;
    background: url("/assets/img/services/pages/master-of-work/check.png") no-repeat left center;
    background-size: 2.3rem;
    padding: 0 0 0 3rem; }
  .master-of-work-balus-page .check_text--h {
    font-weight: 900;
    color: #FF9800; }
  .master-of-work-balus-page .balus_foot {
    background: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 1rem;
    margin: 3rem 0 0;
    display: flex; }
  @media (max-width: 1000px) {
    .master-of-work-balus-page .balus_foot {
      flex-direction: column-reverse; }
    .master-of-work-balus-page .balus_foot--img {
      margin: 1rem 0 0; }
    .master-of-work-balus-page .balus_foot--img img {
      width: 500px;
      max-width: 100%;
      margin: auto;
      display: block; } }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .balus_foot {
      align-items: center; }
    .master-of-work-balus-page .balus_foot--img {
      flex: 0 0 30%;
      margin: 0 2rem 0 0;
      line-height: 0; } }
  .master-of-work-balus-page .issues--item {
    background: #F8F8F8;
    padding: 1rem 1rem 1rem 1.4rem;
    border-radius: 10px;
    margin: 1rem 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }
  .master-of-work-balus-page .issues--item::before {
    content: "";
    display: block;
    background: #FF9800;
    width: 10px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 0 0 10px; }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .issues {
      display: flex;
      flex-wrap: wrap; }
    .master-of-work-balus-page .issues--item {
      flex: 1 0 40%;
      margin: 1rem; } }
  .master-of-work-balus-page .sect_cases {
    background: #F0F8F8; }
  .master-of-work-balus-page .sect_cases .cta {
    background: #fff; }
  .master-of-work-balus-page .cases {
    margin: 5rem 0; }
  .master-of-work-balus-page .cases--item {
    background: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    margin: 1rem 0 2rem; }
  .master-of-work-balus-page .cases--item::before {
    content: "";
    display: block;
    width: 100%;
    height: 10px;
    background: #67BCC5;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px 10px 0 0; }
  .master-of-work-balus-page .cases--item_head {
    background: #67BCC5;
    color: #fff;
    position: absolute;
    top: -1.5rem;
    left: 0;
    width: 5rem;
    text-align: center;
    font-weight: 900;
    border-radius: 10px 10px 0 0;
    line-height: 1;
    padding: .5rem; }
  @media (min-width: 1001px) {
    .master-of-work-balus-page .cases {
      display: flex;
      flex-wrap: wrap; }
    .master-of-work-balus-page .cases--item {
      flex: 1 0 45%;
      margin: 1rem 1rem 2rem; } }
  .master-of-work-balus-page .cases_data {
    display: flex;
    flex-wrap: wrap;
    margin: .5rem 0 1rem; }
  .master-of-work-balus-page .cases_data dt {
    flex: 0 0 6rem;
    font-weight: bold; }
  .master-of-work-balus-page .cases_data dd {
    flex: 0 0 calc(100% - 6rem);
    margin: 0 0 .4rem; }
  .master-of-work-balus-page .concept {
    background: #fff;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); }
  .master-of-work-balus-page .concept--h {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 900;
    margin: 1rem 0;
    color: #0097A7; }
  .master-of-work-balus-page .concept--img img {
    width: 600px;
    max-width: 100%;
    display: block;
    margin: auto; }
  @media (min-width: 801px) {
    .master-of-work-balus-page .concept--body {
      display: flex;
      align-items: center; }
    .master-of-work-balus-page .concept--img {
      flex: 0 0 45%; }
    .master-of-work-balus-page ul.concept--body_text li {
      font-size: 1.1rem; } }
  .master-of-work-balus-page ul.concept--body_text li {
    margin: 0 0 1rem;
    padding: 0 0 0 1rem; }
  .master-of-work-balus-page ul.concept--body_text li::before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #FF9800;
    position: absolute;
    top: .6rem;
    left: 0; }
  .master-of-work-balus-page ::-webkit-scrollbar {
    width: .5rem; }
  .master-of-work-balus-page ::-webkit-scrollbar-track {
    background: #fff; }
  .master-of-work-balus-page ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 1rem; }
  .master-of-work-balus-page ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer; }
  .master-of-work-balus-page .bold-h {
    font-weight: bold;
    font-size: 1.1rem; }
  .master-of-work-balus-page .orange {
    color: #FF9800; }
  .master-of-work-balus-page .emerald {
    color: #0097A7; }
  .master-of-work-balus-page .bg-02 {
    background: #F8F8F8; }
  .master-of-work-balus-page .strong {
    font-weight: 900; }
  .master-of-work-balus-page .text-center {
    text-align: center; }
  .master-of-work-balus-page .text-right {
    text-align: right; }
  .master-of-work-balus-page .br {
    display: inline-block; }
  .master-of-work-balus-page .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .master-of-work-balus-page .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .master-of-work-balus-page .slick-list:focus {
    outline: none; }
  .master-of-work-balus-page .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }
  .master-of-work-balus-page .slick-slider .slick-track,
  .master-of-work-balus-page .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .master-of-work-balus-page .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .master-of-work-balus-page .slick-track:before,
  .master-of-work-balus-page .slick-track:after {
    display: table;
    content: ''; }
  .master-of-work-balus-page .slick-track:after {
    clear: both; }
  .master-of-work-balus-page .slick-loading .slick-track {
    visibility: hidden; }
  .master-of-work-balus-page .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
  .master-of-work-balus-page [dir='rtl'] .slick-slide {
    float: right; }
  .master-of-work-balus-page .slick-slide img {
    display: block; }
  .master-of-work-balus-page .slick-slide.slick-loading img {
    display: none; }
  .master-of-work-balus-page .slick-slide.dragging img {
    pointer-events: none; }
  .master-of-work-balus-page .slick-initialized .slick-slide {
    display: block; }
  .master-of-work-balus-page .slick-loading .slick-slide {
    visibility: hidden; }
  .master-of-work-balus-page .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .master-of-work-balus-page .slick-arrow.slick-hidden {
    display: none; }
  .master-of-work-balus-page .cta-btn-download {
    display: inline-block;
    min-width: 19rem;
    background: #4CAF50;
    background-image: linear-gradient(45deg, #ffb548 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    color: #fff;
    font-weight: 900;
    padding: 10px;
    border-radius: 9rem;
    transition: .3s, background 700ms ease-in-out; }
  .master-of-work-balus-page .cta-btn, .master-of-work-balus-page .cta-btn-download {
    transition: transform 0.3s ease !important;
    /* ホバー時の滑らかな変化 */ }
  .master-of-work-balus-page .cta-btn:hover, .master-of-work-balus-page .cta-btn-download:hover {
    transform: scale(1.1) !important;
    /* ホバー時にボタンを拡大 */ }
  .master-of-work-balus-page p.cta-download-text {
    font-weight: 900; }
  .master-of-work-balus-page .cta-btn-download:hover {
    transform: scale(1.1); }
  .master-of-work-balus-page .cta {
    background-color: #f0f9f9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; }
  .master-of-work-balus-page .cta-left, .master-of-work-balus-page .cta-right {
    text-align: center; }
  .master-of-work-balus-page .cta-text, .master-of-work-balus-page .cta-download-text {
    color: #38a0a0;
    font-size: 18px;
    margin-bottom: 10px; }
  .master-of-work-balus-page .cta-btn, .master-of-work-balus-page .cta-btn-download {
    background-color: #f9a341;
    /* Change color for download button in its class */
    padding: 10px 20px;
    color: white;
    font-size: 16px;
    border-radius: 30px;
    text-decoration: none; }
  .master-of-work-balus-page .cta-btn-download {
    background-color: #4CAF50; }
  .master-of-work-balus-page .cta-divider {
    border-left: 2px solid #cccccc;
    height: 60px;
    margin: 0 20px; }
  @media (min-width: 768px) {
    .master-of-work-balus-page section.sect.sect_02 {
      margin-top: -50px; } }
  .master-of-work-balus-page form .form-group .form-check input[type=checkbox] + label {
    padding-left: 20px; }
  @media (max-width: 768px) {
    .master-of-work-balus-page .cta {
      flex-direction: column;
      align-items: flex-start; }
    .master-of-work-balus-page .cta-divider {
      display: none; }
    .master-of-work-balus-page .cta-left, .master-of-work-balus-page .cta-right {
      width: 100%; }
    .master-of-work-balus-page .cta-btn, .master-of-work-balus-page .cta-btn-download {
      width: 100%;
      text-align: center; } }

.organization .service-page-header {
  background: white;
  background: linear-gradient(90deg, white 0%, #e5f7fb 100%); }
  @media screen and (max-width: 768px) {
    .organization .service-page-header {
      background: #e5f7fb; } }

.organization .fv_btn {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 90px;
  padding-bottom: 120px;
  width: 400px; }
  @media screen and (max-width: 1199px) {
    .organization .fv_btn {
      width: 350px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 991px) {
    .organization .fv_btn {
      width: 260px;
      padding-left: 50px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 768px) {
    .organization .fv_btn {
      position: relative;
      width: 80%;
      left: inherit;
      margin: auto;
      bottom: inherit;
      padding-left: 0;
      padding-bottom: calc(20 / 768 * 100vw); } }
  .organization .fv_btn a {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 0.3rem;
    border-radius: 3px;
    margin-bottom: 2rem;
    text-decoration: none;
    transition: all 0.3s ease;
    -moz-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    -webkit-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    -ms-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4); }
    @media screen and (max-width: 1199px) {
      .organization .fv_btn a {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 991px) {
      .organization .fv_btn a {
        font-size: 1.4rem;
        margin-bottom: 1rem; } }
    @media screen and (max-width: 768px) {
      .organization .fv_btn a {
        padding: 0.6rem 0.3rem; } }
    .organization .fv_btn a.trial {
      background: #0097A7;
      color: #FFFFFF; }
    .organization .fv_btn a.know {
      border: 2px solid #0097A7;
      background: #FFFFFF; }
    .organization .fv_btn a:hover {
      opacity: 0.8;
      box-shadow: none; }

.disruptive-development .service-page-header picture,
.disruptive-development .service-page-header img {
  width: 100%; }

.disruptive-development .success_list {
  position: relative;
  border: 1px solid #0097A7;
  background: #ddf5f9;
  padding: 1rem 2rem !important; }
  .disruptive-development .success_list::before, .disruptive-development .success_list::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    background: #0097A7; }
  .disruptive-development .success_list::before {
    top: -4px;
    left: -4px; }
  .disruptive-development .success_list::after {
    right: -4px;
    bottom: -4px; }

.disruptive-development .strong_text {
  color: #0097A7;
  font-weight: 700; }

.disruptive-development .success2 {
  font-size: 1.2rem;
  letter-spacing: 0.05em; }
  @media screen and (max-width: 768px) {
    .disruptive-development .success2 {
      font-size: 1.0rem; } }

.software .strong_text {
  color: #0097A7;
  font-weight: bold; }

.services-page .navbar_balus .navbar_balus_inner {
  width: 100%; }
  .services-page .navbar_balus .navbar_balus_inner .list_link {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 20px;
    list-style: none;
    padding: 0;
    margin-bottom: 0; }
    .services-page .navbar_balus .navbar_balus_inner .list_link li a {
      display: block;
      border: 2px solid #0097A7;
      width: 100%;
      font-weight: 700;
      font-size: 0.9rem;
      letter-spacing: 0.08em;
      text-align: center;
      padding: 0.1rem 0.8rem;
      border-radius: 3px;
      text-decoration: none;
      transition: all 0.3s ease;
      -moz-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      -webkit-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      -ms-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4); }
      .services-page .navbar_balus .navbar_balus_inner .list_link li a.regist {
        background: #0097A7;
        color: #FFFFFF; }
      .services-page .navbar_balus .navbar_balus_inner .list_link li a.login {
        background: #FFFFFF; }
      .services-page .navbar_balus .navbar_balus_inner .list_link li a:hover {
        opacity: 0.8; }
  @media (max-width: 991px) {
    .services-page .navbar_balus .navbar_balus_inner {
      justify-content: center !important; }
      .services-page .navbar_balus .navbar_balus_inner .list_link {
        grid-template-columns: 100%;
        gap: 15px;
        margin-top: 30px; } }

.services-page .tit_deco1 {
  position: relative;
  padding-bottom: 55px; }
  .services-page .tit_deco1::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1px;
    height: 50px;
    background: #0097A7; }
  .services-page .tit_deco1::after {
    content: "";
    position: absolute;
    bottom: 46px;
    left: calc(50% - 3px);
    width: 7px;
    height: 7px;
    background: #0097A7; }

.services-page .tit_deco3 {
  color: #0097A7;
  border-bottom: 2px solid #0097A7; }

.services-page .fv_link {
  padding: 2rem 0; }
  .services-page .fv_link .list_link {
    max-width: 600px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 60px;
    list-style: none;
    padding: 0; }
    @media (min-width: 992px) {
      .services-page .fv_link .list_link {
        max-width: 800px; } }
    @media screen and (max-width: 768px) {
      .services-page .fv_link .list_link {
        max-width: 80%;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px; } }
    .services-page .fv_link .list_link li a {
      display: block;
      border: 2px solid #0097A7;
      width: 100%;
      font-weight: 700;
      font-size: 1.2rem;
      letter-spacing: 0.08em;
      text-align: center;
      padding: 0.2rem;
      border-radius: 3px;
      text-decoration: none;
      transition: all 0.3s ease;
      -moz-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      -webkit-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      -ms-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
      box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4); }
      @media (min-width: 992px) {
        .services-page .fv_link .list_link li a {
          font-size: 1.4rem;
          padding: 0.3rem; } }
      .services-page .fv_link .list_link li a.regist {
        background: #0097A7;
        color: #FFFFFF; }
      .services-page .fv_link .list_link li a.login {
        background: #FFFFFF; }
      .services-page .fv_link .list_link li a:hover {
        opacity: 0.8; }

.services-page .download_btn {
  display: block;
  width: 100%;
  max-width: 350px;
  margin: auto;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 1rem;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s ease;
  background: #0097A7;
  color: #FFFFFF; }
  .services-page .download_btn:hover {
    opacity: 0.8; }

.services-page .services-breadcrumb .container {
  position: relative; }

.services-page .services-breadcrumb.offset-header {
  margin-top: 64px; }

.services-page .services-breadcrumb .row .services-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: .25rem; }
  @media (max-width: 575px) {
    .services-page .services-breadcrumb .row .services-breadcrumb {
      padding: .75rem 0 .75rem 0.5rem; } }

.services-page .service-page-header.offset-header {
  margin-top: 64px; }

.services-page .service-page-header .container {
  position: relative; }

.services-page.services_index .service-page-header {
  background: #ddf5f9; }

.services-page.services_index .fv_btn {
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 90px;
  padding-bottom: 120px;
  width: 400px; }
  @media screen and (max-width: 1199px) {
    .services-page.services_index .fv_btn {
      width: 350px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 991px) {
    .services-page.services_index .fv_btn {
      width: 260px;
      padding-left: 50px;
      padding-bottom: 70px; } }
  @media screen and (max-width: 768px) {
    .services-page.services_index .fv_btn {
      position: relative;
      width: 80%;
      left: inherit;
      margin: auto;
      bottom: inherit;
      padding-left: 0;
      padding-bottom: calc(20 / 768 * 100vw); } }
  .services-page.services_index .fv_btn a {
    display: block;
    width: 100%;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 0.3rem;
    border-radius: 3px;
    margin-bottom: 2rem;
    text-decoration: none;
    transition: all 0.3s ease;
    -moz-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    -webkit-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    -ms-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
    box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4); }
    @media screen and (max-width: 1199px) {
      .services-page.services_index .fv_btn a {
        margin-bottom: 1.5rem; } }
    @media screen and (max-width: 991px) {
      .services-page.services_index .fv_btn a {
        font-size: 1.4rem;
        margin-bottom: 1rem; } }
    @media screen and (max-width: 768px) {
      .services-page.services_index .fv_btn a {
        padding: 0.6rem 0.3rem; } }
    .services-page.services_index .fv_btn a.trial {
      background: #0097A7;
      color: #FFFFFF; }
    .services-page.services_index .fv_btn a.know {
      border: 2px solid #0097A7;
      background: #FFFFFF; }
    .services-page.services_index .fv_btn a:hover {
      opacity: 0.8;
      box-shadow: none; }

.services-page .fv_header_link {
  background: #f4f4f4;
  padding: 2rem 0; }
  @media screen and (max-width: 768px) {
    .services-page .fv_header_link {
      padding: 0.5rem 0; } }
  .services-page .fv_header_link .link {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .services-page .fv_header_link .link {
        grid-template-columns: repeat(1, 1fr); } }
    .services-page .fv_header_link .link * {
      text-align: center; }
    .services-page .fv_header_link .link div {
      padding: 0 1rem; }
      @media screen and (max-width: 768px) {
        .services-page .fv_header_link .link div {
          padding: 1rem; } }
      .services-page .fv_header_link .link div:nth-of-type(1) {
        border-right: 1px solid #ccc; }
        @media screen and (max-width: 768px) {
          .services-page .fv_header_link .link div:nth-of-type(1) {
            border-right: 0;
            border-bottom: 1px solid #ccc; } }
    .services-page .fv_header_link .link dd {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 0.5rem; }

.services-page .feature_tit {
  width: -moz-fit-content;
  width: fit-content;
  color: #0097A7;
  margin: auto; }

.services-page .feature_list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding-left: 0; }
  @media screen and (max-width: 768px) {
    .services-page .feature_list {
      margin: 0 1rem;
      grid-template-columns: repeat(1, 1fr); } }
  .services-page .feature_list li {
    position: relative;
    border: 1px solid #0097A7;
    padding: 1rem; }
    .services-page .feature_list li::before, .services-page .feature_list li::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      background: #0097A7; }
    .services-page .feature_list li::before {
      top: -4px;
      left: -4px; }
    .services-page .feature_list li::after {
      right: -4px;
      bottom: -4px; }
    .services-page .feature_list li h4 {
      padding-left: 10px;
      border-left: 5px solid #0097A7; }
    .services-page .feature_list li .inner {
      margin-top: 0.5rem; }
      .services-page .feature_list li .inner .image {
        width: 35%; }
      .services-page .feature_list li .inner .text {
        width: 60%; }

@media screen and (max-width: 768px) {
  .services-page .problems_text1 {
    margin: 0 1rem; } }

.services-page .document_list {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-left: 0; }
  @media screen and (max-width: 768px) {
    .services-page .document_list {
      margin: 0 1rem;
      grid-template-columns: repeat(1, 1fr); } }
  .services-page .document_list li img {
    display: block;
    width: 70%;
    max-width: 250px;
    margin: auto; }

.services-page .page_link_btn {
  display: block;
  width: 100vw;
  max-width: 320px;
  margin: 1rem auto 0;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.05cap;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  -moz-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
  -webkit-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
  -ms-box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
  box-shadow: 20px 20px 12px -20px rgba(17, 68, 74, 0.4);
  background: #0097A7;
  color: #FFFFFF; }
  .services-page .page_link_btn:hover {
    opacity: 0.8;
    box-shadow: none; }

.services-page .service_list {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px; }
  @media screen and (max-width: 768px) {
    .services-page .service_list {
      grid-template-columns: repeat(1, 1fr); } }
  .services-page .service_list li {
    line-height: 1.6; }
    .services-page .service_list li .thumb {
      position: relative; }
      .services-page .service_list li .thumb span {
        display: inline-block;
        position: absolute;
        left: 5px;
        bottom: 5px;
        font-size: 0.8rem;
        color: #FFFFFF;
        background: #FF9800;
        border-radius: 3px;
        padding: 0.2rem 0.8rem; }
  .services-page .service_list .page_link_btn {
    font-size: 1rem; }

.services-page .structuring-img {
  display: block;
  margin: 0rem auto;
  width: 700px;
  max-width: 100%; }

.services-page .number_list {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0; }
  .services-page .number_list li {
    text-indent: -1.8em;
    padding-left: 1.8em;
    margin: 0.5rem 0; }
    .services-page .number_list li::before {
      counter-increment: item;
      content: counter(item) ".";
      color: #0097A7;
      font-size: 1.8rem;
      font-weight: 700;
      font-style: italic;
      line-height: 1.4;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      margin-right: 0.5rem; }

.services-page .youtube {
  width: 100%;
  aspect-ratio: 16 / 9; }

.services-page .youtube iframe {
  width: 100%;
  height: 100%; }

.systeming-page .systeming-page-header {
  min-height: calc(50vh - 64px);
  background-color: #FAFAFA; }
  .systeming-page .systeming-page-header.offset-header {
    margin-top: 64px; }
  .systeming-page .systeming-page-header .page-header-image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-size: cover;
    background-position: center center;
    min-height: calc(50vh - 64px - 2rem * 2); }

.systeming-page .heading {
  margin-top: calc(100px - 64px);
  margin-bottom: 2rem;
  padding-top: 64px; }
  .systeming-page .heading:first-child {
    margin-top: -64px;
    padding-top: 64px; }
  .systeming-page .heading.top-heading {
    margin-top: -64px;
    padding-top: 64px; }

@media (min-width: 768px) {
  .systeming-page {
    font-size: 18px; } }

html {
  scroll-behavior: smooth; }

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: #000000; }

.mt-base {
  margin-top: 1rem !important; }

.mb-base {
  margin-bottom: 1rem !important; }

.ml-base {
  margin-left: 1rem !important; }

.mr-base {
  margin-right: 1rem !important; }

.mt-md {
  margin-top: 2rem !important; }

.mb-md {
  margin-bottom: 2rem !important; }

.ml-md {
  margin-left: 2rem !important; }

.mr-md {
  margin-right: 2rem !important; }

.mt-lg {
  margin-top: 100px !important; }

.mb-lg {
  margin-bottom: 100px !important; }

.ml-lg {
  margin-left: 100px !important; }

.mr-lg {
  margin-right: 100px !important; }

.bg-primary {
  background-color: #0097A7 !important; }

.bg-light {
  background-color: #EFEFF4 !important; }

.bg-ultra-light {
  background-color: #FAFAFA !important; }

.bg-primary-light {
  background-color: #EFFCFE !important; }

.section {
  background-color: #FFFFFF;
  padding-top: 64px;
  padding-bottom: 64px; }
  .section.offset-header {
    margin-top: 64px; }
  .section .section-header {
    margin-bottom: 2rem; }
    .section .section-header.right-item {
      display: flex;
      justify-content: space-between;
      align-items: baseline; }
  .section .section-body .separator:last-child {
    display: none; }

.min-h-0 {
  min-height: 0% !important; }

.border {
  border: 1px solid #C6C6C8 !important; }

.border-radius-base {
  border-radius: 0.25rem; }

.index-page .index-page-header {
  position: relative;
  min-height: 100vh;
  color: #FFFFFF; }
  .index-page .index-page-header a {
    color: #FFFFFF; }
  .index-page .index-page-header::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.25); }
  .index-page .index-page-header.offset-header {
    min-height: calc(100vh - 64px);
    margin-top: 64px; }
  .index-page .index-page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .index-page .index-page-header .page-header-content {
    min-height: calc(100vh - 64px);
    display: flex;
    align-items: center; }
    .index-page .index-page-header .page-header-content h1 {
      font-size: 40px; }
  .index-page .index-page-header .page-header-bottom {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%; }

@media (min-width: 768px) {
  body {
    font-size: 16px; }
  .section.section-wide {
    padding-top: 100px;
    padding-bottom: 100px; }
  .index-page .index-page-header .page-header-content h1 {
    font-size: 52px; } }
